import React, {useState} from 'react'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import Image from './image'

const Header = ({siteTitle}) => {
  const [isExpanded, toggleExpansion] = useState(false)
  return (
    <header className='bg-gray-100 p-2 mt-0 fixed w-full z-10 top-0'>
      <div className='flex flex-wrap items-center justify-between max-w-4xl mx-auto p-1'>
        <Link
          className='flex items-center no-underline text-black hover:text-orange-500'
          to='/'
        >
          <Image
            name='logo'
            alt={siteTitle}
            width='124px'
            height='24px'
            className='logo'
          />
        </Link>

        <button
          className='block md:hidden border border-white flex items-center px-3 py-2 rounded text-black hover:text-orange-500'
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className='fill-current h-3 w-3'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/`,
              title: `Inicio`,
            },
            {
              route: `/about-us`,
              title: `Quienes Somos`,
            },
            {
              route: `/clients`,
              title: `Clientes`,
            },
            {
              route: `/contact`,
              title: `Contacto`,
            },
          ].map(link => (
            <Link
              className='block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-black hover:text-orange-500'
              key={link.title}
              to={link.route}
              activeClassName='is-menu-active'
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
