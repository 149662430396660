import React from "react"

const Footer = () => {
  return (
    <footer className="bg-gray-900">
      <div className="container max-w-6xl mx-auto flex items-center px-2 py-8">
        <div className="w-full mx-auto flex flex-wrap items-center">
          <div className="flex w-full md:w-1/2 justify-center md:justify-start text-white font-extrabold">
            <p className="text-gray-900 no-underline hover:text-gray-900 hover:no-underline">
              {" "}
              <span className="text-xs text-gray-200">
                2016 ©{" "}
                <strong className="text-base text-orange-500">
                  Cintanegra{" "}
                </strong>
                | Todos los derechos reservados
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
